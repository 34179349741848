import React from "react";
import PropTypes from "prop-types";

const SmokeLight = ({ background, width, height }) => {
  return <div style={{ background, width, height, maxWidth: "100%" }} />;
};

SmokeLight.propTypes = {
  background: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default SmokeLight;
