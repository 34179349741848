import React from "react";
import styles from "./impactcard.module.scss";

function SvgComponent(props) {
  return (
    <svg
      width={10}
      height={11}
      viewBox='0 0 10 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.512 6.464a.5.5 0 01-.39-.815l3.49-4.31a.5.5 0 01.777 0l3.488 4.31a.5.5 0 01-.388.815H1.512z'
        fill='#29CB97'
      />
    </svg>
  );
}

const ImpactCard = ({
  heading = "25%",
  subheading = "Commercial productivity growth",
  positive,
  top = "unset",
  left = "unset",
  right = "unset",
  bottom = "unset",
  style,
}) => {
  return (
    <div className={styles.container} style={{ top, left, right, bottom, ...style}}>
      <div className={styles.header}>
        {heading}{" "}
        {positive ? (
          <SvgComponent style={{ marginLeft: 5 }} />
        ) : (
          <SvgComponent
            style={{ marginLeft: 5, transform: "rotate(180deg)" }}
          />
        )}
      </div>
      <p className={styles.subheading}>{subheading}</p>
    </div>
  );
};

export default ImpactCard;
