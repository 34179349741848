import React, { useEffect, useState } from "react";
import styles from "./N5Impact.module.scss";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation } from "gatsby-plugin-react-i18next";

import videoImpact from "../../../assets/video/n5impactvideo_x264.mp4";
import videoImpactWebm from "../../../assets/video/n5impactvideo.webm";
import Divider from "../../../assets/svg/dividerCenter.svg";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { useOnScreen } from "../../../hooks/useOnScreen";
import ImpactCard from "@atoms/ImpactCard";
import SmokeLight from "@atoms/SmokeLight";

const greenSmoke =
  "radial-gradient(47.54% 31.45% at 50% 46.27%, rgba(41, 203, 151, 0.7) 0%, rgba(41, 203, 151, 0.301) 40.1%, rgba(41, 203, 151, 0.154) 64.06%, rgba(41, 203, 151, 0) 100%)";

const N5Impact = () => {
  const { t } = useTranslation();

  const [titleRef, titleVisible] = useOnScreen({ threshold: 0.1 });
  const [subTitleRef, subTitleVisible] = useOnScreen({ threshold: 0.1 });
  const [anim, setAnim] = useState({ opacity: 0, y: 20 });
  const [animP, setAnimP] = useState({ opacity: 0, y: 20 });

  const [videoRef, isVideoVisible] = useOnScreen({ threshold: 0.1 });
  const breakpoints = useBreakpoint();

  const style = breakpoints.sm
    ? { transform: "translateX(-50%)", left: "50%" }
    : {};

  useEffect(() => {
    if (titleVisible) {
      setAnim({ opacity: 1, y: 0 });
    }
  }, [titleVisible]);

  useEffect(() => {
    if (subTitleVisible) {
      setAnimP({ opacity: 0.6, y: 0 });
    }
  }, [subTitleVisible]);

  return (
    <section className={`container-fluid text-center ${styles.section}`}>
      <div className='dividerCenter' style={{ marginTop: "60px" }}>
        <img src={Divider} alt="divider" loading="lazy" />
      </div>
      <div className='row'>
        <div className='col-md-8 m-auto'>
          <LazyMotion features={domAnimation}>

          <m.h2
            className={styles.impactTitle}
            ref={titleRef}
            animate={anim}
            transition={{
                type: "spring",
                stiffness: 20,
              }}
              >
            {t("N5_IMPACT.TITLE")}
          </m.h2>
          <m.p
            ref={subTitleRef}
            animate={animP}
            transition={{
                type: "spring",
                stiffness: 20,              
              }}
              className={styles.subtitle}
              >
            {t("N5_IMPACT.SUBTITLE")}
          </m.p>
          </LazyMotion>
        </div>
      </div>

      <div className={styles.cards}>
        <div
          className='relative'
          style={{
            display: "inline-block",
            height: breakpoints.sm ? 332 : "auto",
            width: breakpoints.sm ? "100%" : "auto",
          }}
        >
          {breakpoints.sm && (
            <div
              style={{
                position: "absolute",
                top: "-35%",
                right: "50%",
                transform: "translateX(50%)",
              }}
            >
              <SmokeLight background={greenSmoke} width={865} height={611} />
            </div>
          )}
          <ImpactCard
            heading={t("N5_IMPACT.GRAPHIC.CARD1.HEADING")}
            subheading={t("N5_IMPACT.GRAPHIC.CARD1.SUBHEADING")}
            top={breakpoints.sm ? 0 : 80}
            left={breakpoints.sm ? 0 : "calc(8%)"}
          />
          <ImpactCard
            heading={t("N5_IMPACT.GRAPHIC.CARD2.HEADING")}
            subheading={t("N5_IMPACT.GRAPHIC.CARD2.SUBHEADING")}
            top={breakpoints.sm ? 0 : 80}
            right={breakpoints.sm ? 0 : "unset"}
            left={breakpoints.sm ? "unset" : "calc(27% - 4px)"}
          />
          <ImpactCard
            heading={t("N5_IMPACT.GRAPHIC.CARD3.HEADING")}
            subheading={t("N5_IMPACT.GRAPHIC.CARD3.SUBHEADING")}
            top={breakpoints.sm ? 114 : 80}
            right={breakpoints.sm ? "unset" : 0}
            left={breakpoints.sm ? 0 : "calc(45%)"}
            positive
          />
          <ImpactCard
            heading={t("N5_IMPACT.GRAPHIC.CARD4.HEADING")}
            subheading={t("N5_IMPACT.GRAPHIC.CARD4.SUBHEADING")}
            top={breakpoints.sm ? 114 : 80}
            right={breakpoints.sm ? 0 : 190}
            positive
          />
          <ImpactCard
            heading={t("N5_IMPACT.GRAPHIC.CARD5.HEADING")}
            subheading={t("N5_IMPACT.GRAPHIC.CARD5.SUBHEADING")}
            top={breakpoints.sm ? "unset" : 80}
            right={breakpoints.sm ? "unset" : 40}
            bottom={breakpoints.sm ? 0 : "unset"}
            style={style}
            positive
          />
          <video
            ref={videoRef}
            autoPlay={!breakpoints.sm}
            muted
            style={{ width: 814, display: breakpoints.sm ? "none" : "block" }}
          >
            
            { isVideoVisible && <source src={videoImpactWebm} type="video/webm"></source> }
            { isVideoVisible && <source src={videoImpact} type="video/mp4"></source> }
            Tu navegador no admite el elemento <code>video</code>.
          </video>
        </div>
      </div>
      <div className='dividerCenter'>
        <img src={Divider} alt="divider" loading="lazy" />
      </div>
    </section>
  );
};

export default N5Impact;
